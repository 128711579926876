$class: '.gov-calendar';

#{$class} {
    font-family: $gov-font-family-primary;

    &__container {
        padding: rem-calc(10) rem-calc(5);
        max-width: 280px;

        background-color: gov-colors('blue');
        border-radius: $gov-border-radius;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding-bottom: rem-calc(5);
    }

    &__toggle {
        @extend .gov-button;
        @extend .gov-button--inversed;
        @extend .gov-button--icon-only;

        padding: 0;
        height: 36px;
        width: 36px;

        &:after {
			color: gov-colors('white');
			font-family: $gov-icon-font;
			font-size: rem-calc(24);
			text-transform: none;
            vertical-align: middle;
        }

        &--prev {
            &:after {
                @include pseudo(inline-block, relative, '\63');
            }
        }

        &--next {
            &:after {
                @include pseudo(inline-block, relative, '\65');
            }
        }
    }

    &__title {
        color: gov-colors('white');
        font-size: 12px;
        font-weight: 500;
        letter-spacing: em-calc(0.5);
        text-align: center;
        text-transform: uppercase;
        -webkit-font-smoothing: antialiased;
    }

    &__days {
        margin: 0 auto;

        thead {
            th {
                padding-right: 0;

                color: gov-colors('white');
                font-size: 12px;
                font-weight: 500;
                letter-spacing: em-calc(0.2);
                text-align: center;
                text-transform: uppercase;
                -webkit-font-smoothing: antialiased;
            }
        }

        tbody {
            tr {
                border: none;

                td {
                    padding: 2px;
                    min-width: auto;

                    button {
                        @extend .gov-button;
                        @extend .gov-button--inversed;

                        padding: 0;
                        height: 34px;
                        width: 34px;

                        &.is-today {
                            background-color: gov-colors('blue-dark');
                        }
                    }
                }
            }
        }
    }


    /* Standalone
    ------------------------------------------------------------------ */
    &--standalone {
        #{$class}__container {
            padding: rem-calc(15);
            max-width: 320px;
        }

        #{$class}__header {
            position: relative;

            padding-top: rem-calc(6);
            padding-bottom: rem-calc(20);
            max-width: 280px;
        }

        #{$class}__toggle {
            position: absolute;
            right: rem-calc(-12); top: 0;

            align-self: flex-end;
            order: 2;

            &--prev {
                right: 24px;
            }
        }

        #{$class}__title {
            font-size: rem-calc(20);
            text-transform: none;
        }

        #{$class}__days {
            max-width: 280px;

            tbody {
                tr {
                    td {
                        padding: 2px 0;

                        button {
                            height: 36px;
                            width: 36px;
                        }
                    }
                }
            }
        }
    }
}
