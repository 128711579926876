@import '_colors.scss';
@import '_custom.scss';

//body {
//  margin: 0;
//  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//}
//
//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
//}

.react-datepicker__close-icon {
  right: 25px !important;

  &:after {
    background-color: black !important;
  }
}
