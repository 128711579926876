body, legend {
  background: var(--gov-color-grey-bg)
}

#filter li, #filter > * {
  display: inline-block
}

.gov-portal-nav:before {
  left: calc(100vw - 100%);
  right: calc(100vw - 100%)
}

.gov-breadcrumbs {
  padding-top: 1.75rem;
  border-bottom: 1px solid var(--gov-color-grey-inactive)
}

fieldset {
  border-color: var(--gov-color-grey-light);
  position: relative;
  margin: 1rem 0 0;
  padding: 1.35rem 1.35rem .75rem
}

legend {
  position: absolute;
  top: -.475rem;
  left: 1rem;
  border: 0;
  padding: 0 .875rem;
  color: var(--gov-color-grey-mid);
  font-family: Roboto, sans-serif;
  font-weight: 600;
  letter-spacing: .0125em;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  font-size: .75rem;
  width: auto
}

.context-menu {
  display: none;
  position: absolute;
  background: #fff;
  z-index: 1;
  padding: .75rem 1rem;
  border: solid var(--gov-color-grey-inactive);
  border-width: 0 1px 1px
}

#filter li:before, .context-menu li:before {
  display: none
}

.show-type-context-menu {
  position: relative;
  top: -.125rem
}

.show-type-context-menu:hover > .context-menu {
  display: block;
  right: 0
}

td:hover .context-menu {
  display: block
}

td:hover .context-menu__line {
  padding-top: .75rem
}

#filter > * {
  padding-left: 0;
  margin-left: 0
}

#filter li {
  margin-left: .25rem;
  margin-right: .25rem;
  font-size: 1rem
}

.gov-form-control .gov-form-control__input:disabled ~ .gov-form-control__label, .gov-form-control .gov-form-control__input:read-only ~ .gov-form-control__label, .gov-form-control .gov-form-control__input[aria-disabled=true] ~ .gov-form-control__label, .gov-form-control .gov-form-control__input[readonly] ~ .gov-form-control__label, .gov-form-control.default-behaviour-unset .gov-form-control__label, .gov-form-control.not-empty .gov-form-control__label {
  background: inherit
}

.gov-form-control--custom .gov-form-control__indicator {
  top: .2rem
}

.label__outside {
  color: var(--gov-color-grey-dark);
  font-weight: 400
}

.section__bottom {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid var(--gov-color-grey-inactive)
}

.relative {
  position: relative
}

.gov-table-cover {
  overflow-x: auto
}

.row.justify-content-end {
  max-width: 100%
}

#filter_form + .gov-table-cover {
  margin-top: 1.5rem
}

//.gov-portal-subnav:after {
//  content: url("./../../../public/gov/assets/images/_global/watermarks/_gov-white.svg")
//}

@media (max-width: 992px) {
  #filter {
    margin-top: 1rem
  }
}

div.gov-container, footer.gov-container {
  overflow: hidden
}