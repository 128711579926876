.gov-form-control {
    select {
	    padding: rem-calc(25 20 11);
        padding-right: rem-calc(40);
        width: 100%;

        background-color: gov-colors('white');
        border: 1px solid gov-colors('grey-mid');
        border-radius: $gov-border-radius;
        box-shadow: none;
        color: gov-colors('grey-dark');
        cursor: pointer;
        font-family: inherit;
        font-size: rem-calc(16);
        line-height: 1.3;
	    letter-spacing: 1.02px;
        outline: 0;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }

        &::-ms-expand {
            display: none;
        }

        &:active {
            border-color: gov-colors('blue');
            outline: 0;
        }

        @include hover() {
            border-color: gov-colors('blue');
            outline: 0;
        }

        &:focus {
            border-color: unset;
            box-shadow: 0 0 0 $gov-outline-width gov-colors('blue-focus');
            outline: 0;
        }

        &[aria-disabled="true"],
        &:disabled {
            cursor: not-allowed;

            background-color: gov-colors('grey-bg');
            border-color: gov-colors('grey-light');
            color: gov-colors('grey-light');

            ~ .gov-form-control__label {
                padding-top: rem-calc(20);
                padding-left: rem-calc(20);
                margin-bottom: 0;

                background-color: gov-colors('grey-bg');
                color: rgba(gov-colors('grey-dark-rgb'), 0.5);
                font-size: rem-calc(16);
                cursor: not-allowed;
            }
        }
    }

    //  --------------------
    .gov-select {
        position: relative;

        &:after {
            @include pseudo(inline-block, absolute, '\62');
            right: rem-calc(15); top: 50%;

            margin-top: rem-calc(-12);

            color: gov-colors('blue');
            font-family: $gov-icon-font;
            font-size: rem-calc(24);
            line-height: 1;
            pointer-events: none;
        }

        .gov-form-control__label {
            pointer-events: none;
        }
    }

    /*  Error
    ------------------------------------------------------------------ */
    &--error {
        select {
            color: gov-colors('error');
			border-color: gov-colors('error');

			&:active,
			&:focus {
				border-color: gov-colors('error');
            }

            @include hover() {
                border-color: gov-colors('error');
            }

            &:focus {
                box-shadow: 0 0 0 $gov-outline-width gov-colors('error');
            }
        }

        .gov-select {
            &:after {
                color: gov-colors('error');
            }
        }
    }
}
