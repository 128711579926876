.gov-footer {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(85);

    &__upper {
        display: flex;
        flex-wrap: wrap;
    }

    &__lower {
        display: flex;
        justify-content: space-between;
    }

    &__col {
        padding-right: rem-calc(15);
        padding-bottom: rem-calc(10);
		width: 100%;


        @include mq($from: 34em) {
            width: 48%;
        }

        @include mq($from: tablet) {
            width: 30%;
        }

    @include mq($from: portable) {
      width: 25%;
    }
  }
  &__li-supp {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
  }

  &__image-link {
    &[target='_blank']:after {
      display: none;
    }
  }

  &__image {
    max-width: 80%;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
  }

  &__icon {
    color: white;
  }

  &__supp {
    color: white;
    font-size: rem-calc(12);

    &:hover {
      color: white;
      text-decoration: none;
    }

    @include mq($from: 830px) {
      font-size: rem-calc(10);
    }

    @include mq($from: 870px) {
      font-size: rem-calc(11);
    }

    @include mq($from: 1120px) {
      font-size: rem-calc(12);
    }
  }

    &__headline {
        margin-bottom: rem-calc(15);

		font-size: rem-calc(16);
		color: gov-colors('grey-light');
        text-transform: none;

        @include mq($from: 34em) {
            margin-bottom: rem-calc(25);
        }
    }

    &__scroll-up {
		position: absolute;
		top: 0;
		right: 0;

		width: rem-calc(40);
        height: rem-calc(40);

		&:after {
			@include pseudo(inline-block, absolute, '\62');
			left: 50%; top: 50%;

			color: gov-colors('white');
			font-family: $gov-icon-font;
			font-size: rem-calc(24);
			text-transform: none;
            vertical-align: middle;

			transform: translate(-50%, -50%) rotate(-180deg);
        }

        @include hover() {
            background-color: gov-colors('white') !important;

			&:after {
				color: gov-colors('grey-dark');
			}
        }
    }

    hr {
		margin-bottom: rem-calc(35);
		height: 1px;

		border: none;
		border-bottom: 1px solid gov-colors('grey-mid');
	}

    .gov-container__content {
        position: relative;
    }

    .gov-list--plain {
        &:not(.gov-list--inline) {
            & > li {
                margin-bottom: rem-calc(15);

                line-height: 1;
            }
        }
    }

    /*  RESPONSE
    ------------------------------------------------------------------ */
    @include mq($until: tablet-landscape) {
        padding-top: rem-calc(35);
        padding-bottom: rem-calc(40);

        hr {
            margin-bottom: rem-calc(25);
        }

        &__lower {
            display: block;

            .gov-footnote:first-child {
                margin-bottom: rem-calc(25);
            }
        }
    }

    @include mq($until: mobile-landscape) {
        &__upper {
            .gov-list--inline {
                margin-bottom: 0;
            }
        }
    }
}
