.gov-blocklist-item {
    position: relative;

	padding: rem-calc(35 50 25 50);

    &:before {
        @include pseudo();
        left: 0; right: 0; bottom: 0;

        height: 1px;

        background-color: gov-colors('grey-inactive');
    }

	&--with-icon {
		&:after {
			@include pseudo();
			right: 0; top: 0;

			border-color: transparent;
			border-style: solid;
			border-width: rem-calc(30);
			border-right-color: gov-colors('blue');
			border-top-color: gov-colors('blue');
		}
    }

    &__content {
        & > p,
        & > .gov-paragraph {
            padding-right: rem-calc(40);
        }

        & > .gov-breadcrumbs {
            margin-top: rem-calc(5);
        }
    }

	&__link {
        display: block;
        margin-bottom: rem-calc(10);
        width: 100%;

        & > * {
            display: inline;
            vertical-align: top;
        }

        &:not([target="_blank"]) {
            @extend .gov-link--has-arrow;

            &:after {
                top: rem-calc(1);

                transform: translateY(0);
            }
        }

        &[target="_blank"] {
            display: block;
            margin-right: 0;
        }
    }

    &:last-of-type {
        &:before {
            display: none;
        }
    }

    //  RESPONSE
    @include mq($until: mobile) {
        padding: rem-calc(20);

        &__content {
            & > p,
            & > .gov-paragraph {
                padding-right: 0;
            }
        }

        &__link {
            &:after {
                right: rem-calc(-10);
            }
        }
    }
}
