.gov-accordion {
    &__header {
        padding: rem-calc(10) 0;
        padding-top: rem-calc(20);
        padding-left: 0 !important;

        &:not(:first-of-type) {
            border-top: 1px solid gov-colors('grey-inactive');
        }

        &:before,
        &:after {
            display: none;
        }
    }

    &__content {
        display: block !important;
        height: auto;

        visibility: visible;
    }

    &__content-inner {
        padding-left: 0;
        padding-top: 0;
    }

    &--as-section {
        & > .gov-accordion__header,
        & > .gov-accordion__content > .gov-accordion__content-inner {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
