.gov-alert {
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 0;

    background-color: gov-colors('white');
    border-top: 1px solid gov-colors('grey-inactive');
    border-bottom: 1px solid gov-colors('grey-inactive');

    &:before {
        color: gov-colors('grey-dark') !important;
    }
}
