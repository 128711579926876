.gov-note {
    display: block;
    margin: 0;

    color: gov-colors('grey-dark');
    font-family: $gov-font-family-primary;
    font-size: rem-calc(14);
    font-style: normal;
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;
}
