.gov-content-block {
    padding: rem-calc(20 0);

    hr {
        &.gov-content-block__separator {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
