$c-gov-blue: #0a0841;
$c-gov-error: #c52a3a;
$c-gov-success: #6fbd2c;
$c-gov-yellow: #ecae1a;

:root {
  --toastify-color-success: #6fbd2c !important;
  --toastify-color-warning: #ecae1a !important;
  --toastify-color-error: #c52a3a !important;
}
