$class: '.gov-form-group';

#{$class} {
    @extend .gov-list--plain;

    margin: 0;
    padding: 0;

    &__item {
        position: relative;

        margin-bottom: rem-calc(10) !important;

        line-height: 1.6667;
        -webkit-font-smoothing: antialiased;
    }

    & + .gov-form-control__message {
        margin-left: 0;
    }

    .gov-form-control__indicator {
        top: rem-calc(2);
    }

    /*  Inline
    ------------------------------------------------------------------ */
    &--inline {
        @include mq($from: palm) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;

            #{$class}__item {
                margin-bottom: rem-calc(5) !important;
                padding-right: rem-calc(30);
            }
        }
    }

    /*  Complex
    ------------------------------------------------------------------ */
    &--complex {
        .gov-form-control__label {
            p, .gov-paragraph {
                margin-top: rem-calc(10);
                margin-bottom: rem-calc(15);

                text-transform: none;
            }
        }

        .gov-form-control__indicator {
            top: rem-calc(-3);
        }
    }
}
