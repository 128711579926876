.gov-stepper {
    &__step {
        padding: 0;
        padding-top: rem-calc(20);

        text-align: left;
    }

    &__step-number {
        margin-left: 0;
        margin-bottom: 0;
    }
}
