.gov-footnote {
    margin: 0;

    color: gov-colors('grey-light');
    font-family: $gov-font-family-primary;
    font-size: rem-calc(12);
    letter-spacing: em-calc(0.2);
    line-height: 1.4;

    -webkit-font-smoothing: antialiased;
}
