$class: '.gov-category-list';

#{$class} {
    &__inner {
        display: block;
    }

    &__nav-link {
        display: inline;
    }

    &__col:last-child {
        #{$class}__col-inner:last-of-type {
            border-bottom: 0;
        }
    }

    &__col-inner {
        padding-top: rem-calc(15);

        border-bottom: 1px solid gov-colors('grey-inactive');
    }

    &__back-btn {
        display: none;
    }

    &__subcategories,
    &__tasks {
        #{$class}__col-inner {
            display: block;
        }
    }

    .gov-search {
        display: none;
    }
}
