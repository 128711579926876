$class: '.gov-form-control';

#{$class} {
    &--custom {
        #{$class}__checkbox {
            position: absolute;
			z-index: -1;

            opacity: 0;

            & ~ #{$class}__indicator {
                border-radius: $gov-border-radius;

                &:after {
                    @include pseudo(inline-block, absolute, '\68');
                    left: 0; right: 0; top: 1px; bottom: 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    color: transparent;
                    font-family: $gov-icon-font;
                    font-size: rem-calc(14);
                    text-transform: none;
                }
            }

            &:checked ~ #{$class}__indicator {
                background-color: gov-colors('blue');

				&:after {
                    color: gov-colors('white');
				}
            }

            &:focus ~ #{$class}__indicator {
				box-shadow: 0 0 0 $gov-outline-width gov-colors('blue-focus');
                outline: 0;
            }

            &[aria-disabled="true"],
            &:disabled,
            &[readonly] {
                ~ #{$class}__label {
                    color: gov-colors('grey-light');
                    cursor: not-allowed;
                }

                ~ #{$class}__indicator {
                    border-color: gov-colors('blue-inactive-1') !important;
                }

                &:checked ~ #{$class}__indicator {
                    background-color: gov-colors('blue-inactive-1');
                }
            }
        }


        /*  Error
        ------------------------------------------------------------------ */
        &#{$class}--error {
            #{$class}__checkbox {
                &:checked ~ #{$class}__indicator {
                    background-color: gov-colors('error');
                }

                &:focus ~ #{$class}__indicator {
                    box-shadow: 0 0 0 $gov-outline-width gov-colors('error');
                }
            }
        }

        /*  Inversed
        ------------------------------------------------------------------ */
        &#{$class}--inversed {
            #{$class}__checkbox {
                &:checked ~ #{$class}__indicator {
                    &:after {
                        background-color: gov-colors('white');
                        color: gov-colors('grey-dark');
                    }
                }

                &:focus ~ #{$class}__indicator {
                    box-shadow: 0 0 0 $gov-outline-width gov-colors('white');
                }
            }
        }
    }
}
