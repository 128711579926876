$class: '.gov-blockquote';

blockquote, #{$class} {
    position: relative;

    margin-bottom: rem-calc(25);
    padding: rem-calc(15) rem-calc(25);
    padding-left: rem-calc(50);

    background-color: gov-colors('blue-hover');
    border: none;
    color: gov-colors('grey-mid');
    font-family: $gov-font-family-primary;
    font-size: rem-calc(15);
    font-weight: 400;
    letter-spacing: em-calc(0.2);
    line-height: 1.6;

    -webkit-font-smoothing: antialiased;

    &:before {
        @include pseudo();
        left: 0; top: 0; bottom: 0;

        width: 4px;

        background-color: gov-colors('blue');
    }

    &:after {
        @include pseudo(block, absolute, '\71');
        left: rem-calc(15); top: rem-calc(10); bottom: 0;

        color: gov-colors('blue');
        font-family: $gov-icon-font;
        font-size: rem-calc(24);
    }
}

#{$class} {
    &--example {
        &:after {
            content: '\4a'
        }
    }

    &--math {
        &:after {
            content: '\4b'
        }
    }
}
