.gov-breadcrumbs {
	&__item + &__item {
		&:before {
			@include pseudo(inline-block, relative, '\65');

            color: gov-colors('grey-dark');
			font-family: $gov-icon-font;
			font-size: rem-calc(24);
			text-transform: none;
			vertical-align: middle;

            opacity: .5;
		}
    }

   /*  &__item + &__item {
        white-space: nowrap;

        &:not(:last-of-type) {
            &:after {
                @include pseudo(inline-block, relative, '\65');

                color: gov-colors('grey-dark');
                font-family: $gov-icon-font;
                font-size: rem-calc(24);
                text-transform: none;
                vertical-align: middle;

                opacity: .5;
            }
        }
    } */
}
